.Container{
  width: 85%;
  height: 40%;
  min-width: 50px;
  min-height: 50px;
  transform: translateY(86px) translateX(10px);
};

.Text {
  top: -20px;
};

.FallbackPackage{
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(80px) translateY(155px);
};
