.Container {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    margin-bottom: 65px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    
  };

.Scale {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
};

.HumanHeightIndicator {
  text-align: center;
  white-space: nowrap;
};

.Body {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
};