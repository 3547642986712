@font-face {
  font-family: 'PostSansBold';
  src: 
  url("./fonts/PostSans-Bold.eot") format("eot"),
  url("./fonts/PostSans-Bold.woff2") format("woff2"),
  url("./fonts/PostSans-Bold.woff") format("woff");
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
