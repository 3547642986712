.Package {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;

};

.Indicator {
  position: absolute;
  text-align: center;
  white-space: nowrap;
};