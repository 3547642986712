.TopFace {
  position: absolute;
  background-repeat: no-repeat;
  transform-origin: right top;
  transform: rotateX(-10deg) rotateY(-25deg) rotateX(-90deg);
};

.RightFace {
  position: absolute;
  background-repeat: no-repeat;
  transform-origin: top left;
};

.FrontFace {
  position: absolute;
  background-repeat: no-repeat;
  transform-origin: top right;
  transform: rotateX(-10deg) rotateY(-25deg);
};