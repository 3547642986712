.CompareView{
  font-family: 'PostSansBold', Arial, sans-serif;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 350px;
  max-width: 800px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
};